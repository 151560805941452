@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

div.project {
    width: 380px;
    padding: 10px 10px;
    margin: 10px 20px;
    height: auto;
    max-height: 200px;
    /* background: linear-gradient(45deg, rgb(24, 24, 24) 0%, rgb(102, 102, 102) 100%); */
    background: linear-gradient(45deg, #545F66 0%, rgb(56, 56, 56) 100%);
    /* background-color: #545F66; */
    border-radius: 14px;
    color: white;
}


img.profile {
    border-radius: 100%;
    max-width: 60px;
}

p.vib {
    justify-content: end;
    text-align: center;
    background: linear-gradient(45deg, rgb(77, 110, 0) 0%, rgb(150, 214, 0) 100%);
    ;
    border-radius: 14px;
    width: 100px;
    position: relative;
    float: right;
}

h5 {
    text-align: center;
    font-size: larger;
    margin: 0px;
    padding: 0px;
}

button.view {
    border: none;
    border-radius: 14px;
    background: linear-gradient(45deg, rgb(24, 24, 24) 0%, rgb(102, 102, 102) 100%);
    color: white;
    outline: none;
    width: 150px;
    padding: 5px;
    font-family: 'Open Sans';
    font-weight: bold;
    text-align: center;
    transition: all 0.5s;

}

button.view:hover,
button.view:active {
    cursor: pointer;
    background: linear-gradient(45deg, rgb(24, 24, 24) 0%, rgb(102, 102, 102) 100%);
    box-shadow: 2px 5px 10px black;

    transition: all 0.5s;

}

a.view {
    float: right;
    position: relative;
    bottom: 0px;
    left: 0px;
}

p.language {
    padding: 0px;
    margin: 0px;
    text-align: center;
}

p.res-text {
    font-size: 15px;
    margin: 5px 0px;
}

@media(max-width:500px) {
    div.project {
        width: 100%;
    }

    img.profile {
        width: 50px;
    }

    p.language {
        font-size: 15px;
    }

    p.stats {
        font-size: medium;
    }

    button.view {
        width: 80px;
    }

    p.res-text {
        font-size: xx-small;
        margin: 5px 0px;
    }

    h5 {
        font-size: medium;
    }

    p.vib {
        width: 80px;
        font-size: medium;
    }
}

@media(max-width:940px) {
    div.project {
        max-width: 250px;
    }

    img.profile {
        width: 50px;
    }

    p.language {
        font-size: 15px;
    }

    p.stats {
        font-size: medium;
    }

    button.view {
        width: 80px;
    }

    p.res-text {
        font-size: xx-small;
        margin: 5px 0px;
    }

    h5 {
        font-size: medium;
    }

    p.vib {
        width: 80px;
        font-size: medium;
    }
}