@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

header {
    font-family: Montserrat;
    /* background: linear-gradient(0deg, rgb(56, 56, 56) 0%, rgb(100, 100, 100) 100%); */
    background-color: #545F66;
    color: white;
    height: auto;
    max-height: 150px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 100px;
    gap: 0px 0px;
    grid-template-areas:
        "div1 div2 div2 div2 div3";
}

.div1 {
    grid-area: div1;
    display: flex;
    align-items: center;
}

.div2 {
    grid-area: div2;
    align-items: center;
}


.div3 {
    grid-area: div3;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.logo {
    padding: 10px 20px;
    width: 50px;
    /* background: linear-gradient(45deg, blue 0%, rgb(130, 130, 255) 100%); */
}


main.pages {
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

a {
    margin: 0px;
    text-decoration: none;
    color: white;
    padding: 0px 20px;
}

p.header-links {
    margin: 0px;
}

button.login {
    font-family: Montserrat;
    font-size: large;
    border-radius: 7px;
    height: 50px;
    min-width: 90px;
    width: auto;
    text-align: center;
    border: none;
}

button.login:hover,
button.login:active {
    cursor: pointer;
    box-shadow: 2px 5px 20px black;
}

@media (max-width: 500px) {
    p.header-links {
        font-size: small;
        padding: 0px 15px;
    }

    a {
        padding: 0px;
    }

    header {
        height: 80px;
        grid-template-columns: 1fr 1fr 1fr 1fr 0fr;
    }

    main.pages {
        height: 80px;

    }

    .div2 {
        width: 200px;
    }

    img.logo {
        height: 40px;
        padding: 5px;

    }

    .div1 {
        grid-area: div1;
        display: flex;
        align-items: center;
        height: 80px;
    }

    p {
        font-size: x-large;
    }
}