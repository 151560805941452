@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --imgWidth: 500px;
}

div.about {
    /* background: linear-gradient(180deg, rgb(56, 56, 56) 0%, rgb(100, 100, 100) 20%, rgb(100, 100, 100) 100%); */
    background-color: #93B7BE;
    display: block;
    height: 800px;
    max-height: 2000px;
    height: auto;
    width: auto;
    color: white;
    font-family: Montserrat;
    padding: 20px 10px 10rem;
}

.about {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    grid-template-rows: 0.1fr 1.5fr 0.5fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "header header"
        "img text"
        "img btn";
}

div.img {
    grid-area: img;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

}

.btn {
    grid-area: btn;
}

.text {
    grid-area: text;
    display: flex;
    justify-content: center;
    align-items: center;

}

text.aboutme {
    border-radius: 24px;
    width: 75%;
    height: 70%;
    padding: 20px;
    font-size: large;
    font-weight: 600;
    background-color: #545F66;
    box-shadow: 2px 5px 10px #554348;
}


.header {
    grid-area: header;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    font-weight: 900;
    letter-spacing: 3px;
    text-shadow: 2px 5px 10px black;
    margin: 50px 0px 50px;
}

img.image {
    width: 300px;
    text-align: center;
    background-color: #545F66;

    height: 300px;


    border-radius: 100%;
    grid-area: img;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    box-shadow: 0px 0px 20px black;



}

div.image-center {
    color: white;
    position: absolute;
    width: 80px;
    /* height: auto; */
    height: 80px;
    border-radius: 100%;
    background-color: #545F66;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 900;
    letter-spacing: 2px;
    box-shadow: 0px 0px 20px black;
}

p.about-center {
    font-size: x-small;
}

@media (min-width:600px) {
    img.image {
        width: 400px;
        height: 400px;
    }

    div.image-center {
        width: 100px;
        height: 100px;
    }

    p.about-center {
        font-size: medium;
    }
}

@media (min-width:1200px) {
    img.image {
        width: 500px;
        height: 500px;
    }

    div.image-center {
        width: 150px;
        height: 150px;
    }

    p.about-center {
        font-size: large;
    }
}