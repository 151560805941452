selection.resp {
  min-height: 500px;
  height: auto;
  max-height: 1500px;
  flex-direction: column;
  margin-inline: auto;

}

body {
  background-color: #8C9A9E;
}

div.title {
  display: flex;
  color: white;
  padding: 50px 0px 10px;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  font-weight: 900;
  letter-spacing: 3px;
  text-shadow: 2px 5px 10px black;
}

div.projects {
  margin: 50px 20px;
  border-radius: 24px;
  padding: 50px 10px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  background-color: #93B7BE;
  box-shadow: 0px 0px 40px #93B7BE;
}

div.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



img.App-logo {
  margin: 20px;
}